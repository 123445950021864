<template>
  <Card>
    <template #content>
      <DataTable :value="historicos" responsiveLayout="scroll">
        <template #empty> Nenhum Histórico encontrado. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <Column field="tipo" header="Tipo"></Column>
        <Column field="motivo" header="Motivo"></Column>
        <Column field="dataDocumento" header="Data do documento">
          <template #body="{ data }">
            {{ formatarData(data.dataDocumento) }}
          </template>
        </Column>
        <Column field="numeroOficio" header="Número do ofício"></Column>
        <Column field="numeroProcesso" header="Número do processo"></Column>
        <Column field="juizado" header="Juizado"></Column>
        <Column field="nomeJuiz" header="Juiz"></Column>
        <Column field="observacao" header="Observação"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import moment from 'moment'
import HistoricoConsignacaoService from '@/service/HistoricoConsignacaoService'

export default {
  data() {
    return {
      id: this.$route.params.idconsig,
      historicos: [],
    }
  },

  created() {
    this.service = new HistoricoConsignacaoService(this.$http)
    this.service
      .getOneByIdConsignacao(this.id)
      .then((res) => {
        this.historicos = res
      })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },

  methods: {
    formatarData(value) {
      if (!value) return ''
      else {
        this.data = moment(value).format('DD/MM/YYYY')
        return this.data
      }
    },

    moment: function () {
      return moment()
    },
  },
}
</script>

<style></style>
